import React from "react"
import "../styles/DemandSupplyDateSlider.css"
import ReactSlider from "react-slider"
import { vintageColors, ifStringConvertToDate } from "../pages/DemandSupply"

const firstOfThisMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  1
)

function getMonthsBetweenDates(date1, date2) {
  const startDate = new Date(date1)
  const endDate = new Date(date2)

  let years = endDate.getFullYear() - startDate.getFullYear()
  let months = endDate.getMonth() - startDate.getMonth()

  // Adjust for a negative month difference
  if (months < 0) {
    years--
    months += 12
  }

  return years * 12 + months
}

const trackIndexToThumbIndex = (trackIndex, allValues) => {
  if (trackIndex === 0 || trackIndex === allValues.length) {
    return "x"
  }
  return trackIndex - 1
}

const baseZIndex = 10
/*
This component is used to display the date ranges for the vintage transitions and BOM periods.
The vintage transitions will be fixed (cannot be changed) and the BOM periods will be dynamic.
The dates on the track should be the sectionWidth apart.
*/
const DatesSlider = ({
  allowEdit,
  vintageTransitions,
  bomPeriods,
  sectionWidth,
  dates,
  updateBOMPeriodDate,
}) => {
  const allThumbs = []
  vintageTransitions.forEach((vintageTransition) => {
    const [startYear, startMonth, startDay] = vintageTransition.startDate
      .split("-")
      .map(Number)
    const [endYear, endMonth, endDay] = vintageTransition.endDate
      .split("-")
      .map(Number)
    const startDate = new Date(startYear, startMonth - 1, startDay)
    const endDate = new Date(endYear, endMonth - 1, endDay)

    allThumbs.push({
      type: "vintageTransition",
      startOrEnd: "startDate",
      vintage: vintageTransition.vintage,
      date: vintageTransition.startDate,
      monthsFromThisMonth: getMonthsBetweenDates(firstOfThisMonth, startDate),
      label: vintageTransition.vintage + "->",
    })
    allThumbs.push({
      type: "vintageTransition",
      startOrEnd: "endDate",
      date: vintageTransition.endDate,
      vintage: vintageTransition.vintage,
      monthsFromThisMonth: getMonthsBetweenDates(firstOfThisMonth, endDate),
      label: "<-" + vintageTransition.vintage,
    })
  })
  bomPeriods?.forEach((bomPeriod, index) => {
    const startDate = ifStringConvertToDate(bomPeriod.startDate)
    const endDate = ifStringConvertToDate(bomPeriod.endDate)

    allThumbs.push({
      bomPeriodIndex: index,
      type: "bomPeriod",
      startOrEnd: "startDate",
      date: bomPeriod.startDate,
      vintage: bomPeriod.periodName,
      monthsFromThisMonth: getMonthsBetweenDates(firstOfThisMonth, startDate),
      label: (bomPeriod.periodName || "NEW") + "->",
    })
    allThumbs.push({
      bomPeriodIndex: index,
      type: "bomPeriod",
      startOrEnd: "endDate",
      date: bomPeriod.endDate,
      vintage: bomPeriod.periodName,
      monthsFromThisMonth: getMonthsBetweenDates(firstOfThisMonth, endDate),
      label: "<-" + (bomPeriod.periodName || "NEW"),
    })
  })
  return (
    <div className="DemandSupplyDS datesSlider">
      <ReactSlider
        disabled={!allowEdit}
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        markClassName="mark"
        snapDragDisabled={true}
        min={0}
        max={dates.length - 1}
        step={1}
        marks={true}
        value={allThumbs.map((thumb) => thumb.monthsFromThisMonth)}
        renderThumb={(props, state) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: baseZIndex + state.index,
            }}
            className={`${props.className} ${allThumbs[state.index]?.type} ${
              allThumbs[state.index]?.startOrEnd
            }`}
          >
            {/* <div className="arrow"></div> */}
            {allThumbs[state.index]?.label}
          </div>
        )}
        renderMark={(props) => {
          return <div {...props}></div>
        }}
        onChange={(newValues, thumbIndex) => {
          const changedThumb = allThumbs[thumbIndex]
          if (changedThumb.type === "vintageTransition") {
            return
          }
          const firstOfThisMonthCopy = new Date(firstOfThisMonth)
          const newDateForThumb = new Date(
            firstOfThisMonthCopy.setMonth(
              firstOfThisMonthCopy.getMonth() + newValues[thumbIndex]
            )
          )
          updateBOMPeriodDate(
            changedThumb.bomPeriodIndex,
            changedThumb.startOrEnd,
            newDateForThumb
          )
        }}
        renderTrack={(props, state) => {
          const vintage =
            allThumbs[trackIndexToThumbIndex(state.index, state.value)]?.vintage
          return (
            <div
              {...props}
              className={`${props.className} track`}
              style={{
                ...props.style,
                zIndex: baseZIndex - state.index,
                backgroundColor: vintage
                  ? vintageColors[vintage]
                    ? `rgba(${vintageColors[vintage].r}, ${vintageColors[vintage].g}, ${vintageColors[vintage].b}, 1)`
                    : "grey"
                  : "grey",
              }}
            ></div>
          )
        }}
      />
    </div>
  )
}

export default DatesSlider
