import Page from "../components/Page"
import {
  Button,
  Stack,
  LinearProgress,
  Dialog,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useNavigate } from "react-router-dom"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { useEffect, useMemo, useState } from "react"
import "../styles/Demand.css"
import { formatNumber, demandTypes } from "./DemandSupply"

const CreateDemand = ({ handleCreateNewDemand, close }) => {
  const [newDemand, setNewDemand] = useState({
    type: "B2B",
    description: "",
    allocationBlend: "",
    bomType: "Single",
    sku: "",
    customer: "",
  })

  return (
    <div className="CreateDemand form">
      <div className="header">NEW DEMAND</div>
      <div className="formRow">
        <FormControl fullWidth>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            value={newDemand.type}
            onChange={(e) =>
              setNewDemand({ ...newDemand, type: e.target.value })
            }
            label="Type"
          >
            <MenuItem value={"B2B"}>B2B</MenuItem>
            <MenuItem value={"CG"}>CG</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="formRow">
        <TextField
          fullWidth
          label="Description"
          value={newDemand.description}
          onChange={(e) =>
            setNewDemand({ ...newDemand, description: e.target.value })
          }
        />
      </div>
      <div className="formRow">
        <TextField
          fullWidth
          label="Allocation Blend"
          value={newDemand.allocationBlend}
          onChange={(e) =>
            setNewDemand({ ...newDemand, allocationBlend: e.target.value })
          }
        />
      </div>
      <div className="formRow">
        <FormControl fullWidth>
          <InputLabel id="bomType-label">BOM Type</InputLabel>
          <Select
            labelId="bomType-label"
            value={newDemand.bomType}
            onChange={(e) =>
              setNewDemand({ ...newDemand, bomType: e.target.value })
            }
            label="BOM Type"
          >
            <MenuItem value={"Single"}>Single</MenuItem>
            <MenuItem value={"Date Bound"}>Date Bound</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="formRow">
        <TextField
          fullWidth
          label="SKU"
          value={newDemand.sku}
          onChange={(e) => setNewDemand({ ...newDemand, sku: e.target.value })}
        />
      </div>
      <div className="formRow">
        <TextField
          fullWidth
          label="Customer"
          value={newDemand.customer}
          onChange={(e) =>
            setNewDemand({ ...newDemand, customer: e.target.value })
          }
        />
      </div>

      <Stack
        direction="row"
        spacing={1}
        alignItems={"flex-start"}
        style={{ marginTop: "10px" }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => handleCreateNewDemand(newDemand)}
        >
          SAVE
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => close()}
        >
          CANCEL
        </Button>
      </Stack>
    </div>
  )
}

const forecastArrayToMonthObject = (forecastArray) => {
  const monthObject = {}
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const numMonths = 60
  for (let i = 0; i < numMonths; i++) {
    const month = (currentMonth + i) % 12
    const year = currentYear + Math.floor((currentMonth + i) / 12)
    monthObject[`${month + 1}/${year}`] = 0
  }

  forecastArray.forEach((forecast) => {
    const month = parseInt(forecast.date.split("-")[1])
    const year = forecast.date.split("-")[0]
    if (monthObject[`${month}/${year}`] !== undefined) {
      monthObject[`${month}/${year}`] =
        monthObject[`${month}/${year}`] + forecast.quantity
    }
  })

  return monthObject
}

const EditForecast = ({ forecast, demandItem, cancel, save, units }) => {
  const [forecastArray, setForecastArray] = useState(
    forecast?.demandItemForecastDetails || []
  )

  const monthObject = forecastArrayToMonthObject(forecastArray)

  // forecast arr dates are in format YYYY-MM-DD
  const onNumberChange = (e, monthInMYYYY) => {
    const month = ("0" + monthInMYYYY.split("/")[0]).slice(-2)
    const year = monthInMYYYY.split("/")[1]
    const thisYearMonthDateStr = `${year}-${month}-01`

    const valueRaw = e.target.value
    const valueParsed = valueRaw === "" ? 0 : parseFloat(valueRaw)
    const value = isNaN(valueParsed) ? 0 : valueParsed

    const existingForecast = forecastArray.find((forecast) => {
      return forecast.date === thisYearMonthDateStr
    })

    const newForecastArray = existingForecast
      ? forecastArray.map((forecast) => {
          if (forecast.date === thisYearMonthDateStr) {
            return {
              ...forecast,
              quantity: value,
            }
          }
          return forecast
        })
      : [
          ...forecastArray,
          {
            date: thisYearMonthDateStr,
            quantity: value,
          },
        ]

    setForecastArray(newForecastArray)
  }

  return (
    <div className="EditForecast">
      <div className="head">
        <span className="title">
          {demandItem.type} - {demandItem.allocationBlend}{" "}
          {demandItem.sku ? "- " + demandItem.sku : ""}
        </span>
        {demandItem.customer && demandItem.customer !== "" && (
          <span className="chip">{demandItem.customer}</span>
        )}
      </div>
      <div className="units">Units: {units}</div>
      <div className="forecastRow">
        {Object.keys(monthObject).map((month, index) => (
          <div key={`month${index}`} className="month">
            <div className="date">{month}</div>
            <div>
              <input
                type="text"
                value={monthObject[month]?.toFixed(0)}
                onChange={(e) => onNumberChange(e, month)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="buttons">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            save(forecastArray)
          }}
        >
          SAVE
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            cancel()
          }}
        >
          CANCEL
        </Button>
      </div>
    </div>
  )
}

const Forecast = ({ forecast }) => {
  const monthObject = forecastArrayToMonthObject(
    forecast.demandItemForecastDetails
  )
  return (
    <div className="Forecast">
      <div className="forecastRow">
        {Object.keys(monthObject).map((month, index) => (
          <div key={`month${index}`} className="month">
            <div className="date">{month}</div>
            <div>{formatNumber(monthObject[month], 0)}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const DemandItem = ({ demand, saveForecast }) => {
  const [editForecast, setEditForecast] = useState(null)
  const forecast =
    demand.demandItemForecasts && demand.demandItemForecasts.length > 0
      ? demand.demandItemForecasts[0]
      : null

  const handleSaveForecast = (forecastArray) => {
    saveForecast(
      demand.id,
      forecastArray,
      demand.type === "CG" ? "9LEQ" : "GAL"
    )
    setEditForecast(null)
  }

  return (
    <div className="DemandItem">
      <div className="head">
        <span className="title">
          {demand.type} - {demand.allocationBlend}{" "}
          {demand.sku ? "- " + demand.sku : ""}
        </span>
        {demand.customer && demand.customer !== "" && (
          <span className="chip">{demand.customer}</span>
        )}
        <span className="chip">
          {demand.bomType ? "BOM Type: " + demand.bomType : ""}
        </span>
        {forecast &&
          forecast.demandItemForecastDetails &&
          forecast.demandItemForecastDetails.length > 0 && (
            <span className="units chip">
              {forecast.demandItemForecastDetails[0].units}
            </span>
          )}
        {forecast && (
          <button
            onClick={() => {
              setEditForecast({
                demandId: demand.id,
                forecast: forecast,
              })
            }}
          >
            EDIT FORECAST
          </button>
        )}
        <button
          onClick={() => {
            setEditForecast({
              demandId: demand.id,
              forecast: null,
            })
          }}
        >
          NEW FORECAST
        </button>
      </div>
      <div className="forecastCont">
        {forecast ? (
          <Forecast forecast={forecast} />
        ) : (
          <div className="noForecast">No Forecast</div>
        )}
      </div>
      {!!editForecast && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={!!editForecast}
          onClose={() => setEditForecast(null)}
        >
          <Box className="DemandPage" style={{ padding: "10px" }}>
            <EditForecast
              demandItem={demand}
              forecast={editForecast.forecast}
              cancel={() => setEditForecast(null)}
              save={(forecastArray) => {
                handleSaveForecast(forecastArray)
              }}
              units={
                forecast?.demandItemForecastDetails[0].units ||
                (demand.type === "CG" ? "9LEQ" : "GAL")
              }
            />
          </Box>
        </Dialog>
      )}
    </div>
  )
}

const DemandList = ({ demandsList, saveForecast }) => {
  return (
    <div className="DemandList">
      {demandsList?.map((demand, index) => (
        <DemandItem
          demand={demand}
          key={`xckjhcsdij${index}`}
          saveForecast={saveForecast}
        />
      ))}
    </div>
  )
}

const DEMAND_LIST_URL = "/api/demand"

export const DemandPage = () => {
  const [filterState, setFilterState] = useState({
    type: null,
  })

  const navigate = useNavigate()
  const { execute: executePost, isLoading } = useFetchWithMsal()
  const { execute: executePostNewDemand, isLoading: newDemandLoading } =
    useFetchWithMsal()
  const {
    execute: executeGetDemands,
    isLoading: listLoading,
    data: demandsList,
  } = useFetchWithMsal()
  const [newDemand, setNewDemand] = useState(null)

  useEffect(() => {
    executeGetDemands("GET", DEMAND_LIST_URL)
  }, [executeGetDemands])

  const handleCreateNewDemand = async (newDemandInput) => {
    await executePostNewDemand("POST", DEMAND_LIST_URL, newDemandInput)
    setNewDemand(null)
    executeGetDemands("GET", DEMAND_LIST_URL)
  }

  const saveForecast = async (demandId, forecastArray, units) => {
    await executePost("POST", "/api/demand/forecast", {
      demandId: demandId,
      forecastDetails: forecastArray,
      units: units,
    })
    executeGetDemands("GET", DEMAND_LIST_URL)
  }

  const filteredDemandsList = useMemo(() => {
    if (!demandsList) {
      return []
    }

    return demandsList.filter((demand) => {
      if (filterState.type && demand.type !== filterState.type) {
        return false
      }
      return true
    })
  }, [demandsList, filterState])

  return (
    <Page fullHeight className="Demand">
      {listLoading || isLoading || newDemandLoading ? (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      ) : null}
      <div className="cont">
        <div className="ribbon">
          <Stack direction="row" spacing={1} alignItems={"flex-start"}>
            <ToggleButtonGroup
              exclusive
              variant="outlined"
              value={filterState.type}
              onChange={(event, newType) =>
                setFilterState({ ...filterState, type: newType })
              }
            >
              {demandTypes.map((type, index) => (
                <ToggleButton key={`xdij${index}`} value={type}>
                  {type}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setNewDemand({})}
            >
              NEW DEMAND
            </Button>
            <Button
              disabled={isLoading}
              size="small"
              variant="outlined"
              color="primary"
              onClick={async () => {
                await executePost("POST", "/api/demand/convertexternal", null)
              }}
            >
              CONVERT CG External FORECAST
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/demand/supplyplan")}
              endIcon={<OpenInNewIcon />}
            >
              Supply Plan
            </Button>
          </Stack>
        </div>
        <div className="bodyCont">
          <DemandList
            demandsList={filteredDemandsList}
            saveForecast={saveForecast}
          />
        </div>
      </div>
      <Dialog fullWidth open={!!newDemand} onClose={() => setNewDemand(null)}>
        <Box style={{ padding: "10px" }}>
          {newDemand && (
            <CreateDemand
              newDemand={newDemand}
              handleCreateNewDemand={(i) => handleCreateNewDemand(i)}
              close={() => setNewDemand(null)}
            />
          )}
        </Box>
      </Dialog>
    </Page>
  )
}
